import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import LoginScreen from './component/loginScreen';
import RegisterScreen from './component/registerScreen';
import ForgonPasswordScreen from './component/forgotpasswordScreen';
import { Route, Switch } from "react-router-dom";
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';

import logo from './img/logo.svg'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#a9848b' },
    secondary: { main: '#ffffff' }
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  logo: {
    margin: `${theme.spacing.unit * 3}px`,
    width: '175px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

class Auth extends Component {

  render() {
    const {classes} = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <main className={classes.main}>
          <CssBaseline />
          <a href="/">
            <CardMedia component='img' src={logo} className={classes.logo} />
          </a>
          <Switch>
            <Route path="/register" component={RegisterScreen} />
            <Route path="/forgotpassword" component={ForgonPasswordScreen} />
            <Route component={LoginScreen} />
          </Switch>
        </main>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Auth);
