import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 2,
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  register: {
    marginTop: theme.spacing.unit * 7,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 3,
    }
  },
  registerLink: {
    textDecoration: 'none',
    color: '#fff',
  }
});

class LoginScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: ""
    };
  }

  isFormValid = () => {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    let formData = new FormData()
    formData.append('username', this.state.username)
    formData.append('password', this.state.password)
    fetch('/perform_login', {
      method: 'POST',
      credentials: 'same-origin',
      body: formData
    }).then(response => {
      if (response.redirected) {
        window.location.href = response.url
      }
    }).catch(e => console.error(e))
  }

  render() {
    const params = new URLSearchParams(window.location.search)
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography align="center" component="h5" variant="h5">
          LOG IN TO YOUR ACCOUNT
          </Typography>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          {params.get('error') &&
            <Typography component="span" color="error" align="left">wrong email address or password</Typography>
          }
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="username"
              name="username"
              value={this.state.username}
              autoComplete="email"
              onChange={this.handleChange}
              autoFocus />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input id="password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
              autoComplete="current-password" />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            // disabled={!this.isFormValid()}
            color="primary"
            className={classes.submit}
          >
            Log in
          </Button>
        </form>

        <div className={classes.register}>
          <Typography align="center" component="h6" variant="h6">Have no account yet?</Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
          >
            <Link className={classes.registerLink} to="/register">REGISTER</Link>
          </Button>
        </div>
        <br />
        <Link to="/forgotpassword">Forgot password?</Link>
      </div>
    );
  }
}

export default withStyles(styles)(LoginScreen);
