import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 2,
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  register: {
    marginTop: theme.spacing.unit * 7,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 3,
    }
  },
  registerLink: {
    textDecoration: 'none',
    color: '#fff',
  },
  termsLinks: {
    color: '#000'
  }
});

class RegisterScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password1: "",
      password2: "",
      gender: "",
      news: true,
      errors: []
    };
  }

  handleGenderChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleNewsChange = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const createUserRequest = {
      email: this.state.username,
      password: this.state.password1,
      gender: this.state.gender,
      spamAllowed: this.state.news
    }
    fetch('/api/users/register', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(createUserRequest)
    })
      .then(res => {
        if (res.ok) {
          window.location = res.headers.get('Location')
        } else {
          res.json().then(errorDetails => {
            this.setState({
              error: errorDetails.message
            })
          })
        }
      })
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (password) => {
      const { password1 } = this.state;
      return (password1.length === 0 || password.length === 0)
        || password1 === password;
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container} >
        <Typography align="center" variant="h5">
          REGISTER A NEW ACCOUNT
          </Typography>
        <ValidatorForm className={classes.form} onSubmit={this.handleSubmit} ref="form">
          {this.state.error &&
            <Typography component="span" color="error" align="left">{this.state.error}</Typography>
          }
          <SelectValidator label="Gender"
            value={this.state.gender}
            name="gender"
            id="gender"
            onChange={this.handleGenderChange}
            validators={['required']}
            errorMessages={['gender is required']}
            fullWidth
            margin="normal"
          >
            <MenuItem value="FEMALE">Female</MenuItem>
            <MenuItem value="MALE">Male</MenuItem>
          </SelectValidator>
          <TextValidator label="Email Address"
            id="username"
            name="username"
            value={this.state.username}
            autoComplete="email"
            onChange={this.handleChange}
            fullWidth
            validators={['required', 'isEmail']}
            errorMessages={['email is required', 'must be a valid email address']}
            margin="normal"
          />
          <TextValidator label="Password"
            id="password1"
            name="password1"
            type="password"
            value={this.state.password1}
            onChange={this.handleChange}
            validators={['required', 'minStringLength:8', 'maxStringLength:32']}
            errorMessages={['password is required', 'password must be at least 8 chars', 'password must not be longr that 32 chars']}
            margin="normal"
            autoComplete="new-password"
            fullWidth
          />
          <TextValidator label="Repeat password"
            id="password2"
            name="password2"
            type="password"
            value={this.state.password2}
            onChange={this.handleChange}
            validators={['required', 'minStringLength:8', 'maxStringLength:32', 'isPasswordMatch']}
            errorMessages={['password is required', 'password must be at least 8 chars', 'password must not be longr that 32 chars', 'passwords must match']}
            margin="normal"
            autoComplete="new-password"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                id="news"
                checked={this.state.news}
                onChange={this.handleNewsChange}
                color="primary"
                value="news"
              />
            }
            margin="normal"
            label="sign me up for updates from ScentStyle"
          />
          <Typography align="center">By hitting the "Register" button, you agree to the</Typography>
          <Typography align="center"><a className={classes.termsLinks} href="/terms.html" target="_blank">Terms and Conditions</a> and <a className={classes.termsLinks} href="/privacy.html" target="_blank">Privacy Policy</a></Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >Register</Button>
        </ValidatorForm>

        <div className={classes.register}>
          <Typography align="center" variant="h6">Have an account already?</Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
          >
            <Link className={classes.registerLink} to="/">back to LOG IN</Link>
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RegisterScreen);
