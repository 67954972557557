import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 2,
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  register: {
    marginTop: theme.spacing.unit * 7,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 3,
    }
  },
  registerLink: {
    textDecoration: 'none',
    color: '#fff',
  }
});

class ForgonPasswordScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    fetch('/api/users/resetPassword', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({email: this.state.username})
    })
    .then(res => {
      if (res.ok) {
        var loginUrl = res.headers.get('Location')
        window.location.href = loginUrl
      } else {
        res.json().then(errorDetails => {
          this.setState({
            error: errorDetails.message
          })
        })
      }
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container} >
        <Typography align="center" variant="h5">
          RESET MY PASSWORD
          </Typography>
        <Typography component="span" color="error" align="left">{this.state.error}</Typography>
        <ValidatorForm className={classes.form} onSubmit={this.handleSubmit} ref="form">
          <TextValidator label="Email Address"
            id="username"
            name="username"
            value={this.state.username}
            autoComplete="email"
            onChange={this.handleChange}
            fullWidth
            validators={['required', 'isEmail']}
            errorMessages={['email is required', 'must be a valid email address']}
            margin="normal"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >Reset password</Button>
        </ValidatorForm>

        <div className={classes.register}>
          <Typography align="center" variant="h6">Have an account already?</Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
          >
          <Link className={classes.registerLink} to="/">back to LOG IN</Link>
          </Button>
        </div>
      </div>
      );
  }
}

export default withStyles(styles)(ForgonPasswordScreen);